.history-results {
    >span {
        margin-left: 5px;
    }

    margin-inline: 5px
}

.vela-results {
    margin-inline: 3px;
    text-align: center;
    color: white;
}

.odd-method-select {
    margin-right: 10px;
}

.card-table {
    height: 400px;
    overflow: auto!important;
}

.aviator-content {
    position: relative;
    margin-bottom: 16px;

    .loading-puff {
        position: absolute;
        top: 82px;
        left: 100px;
    }

    .odd-aviator {
        position: absolute;
        top: 91px;
        left: 82px;
        font-weight: bold;
    }
}

.logo {
    &-mini {
        border-radius: 8px;
    }

    &-aviator-pro {
        display: block;
        background: url("./../../../img/aviator-pro.jpg") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-aviator-10 {
        display: block;
        background: url("./../../../img/aviator10.PNG") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-fortune-tiger {
        display: block;
        background: url("./../../../img/tiger.PNG") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-fortune-mouse {
        display: block;
        background: url("./../../../img/fortune_mouse.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-roleta-br {
        display: block;
        background: url("./../../../img/roleta_br_e.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-roleta-da-sorte {
        display: block;
        background: url("./../../../img/roleta_sorte.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-roleta-ao-vivo {
        display: block;
        background: url("./../../../img/roleta_ao_vivo_e.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-mines {
        display: block;
        background: url("./../../../img/mines.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-stock-market {
        display: block;
        background: url("./../../../img/stock.PNG") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }
    
    &-dashboard-aviator {
        display: block;
        background: url("./../../../img/dashboard aviator.png") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-cashmania {
        display: block;
        background: url("./../../../img/cashmania.jpg") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }

    &-fortune-dragon {
        display: block;
        background: url("./../../../img/fortune-dragon.jpg") no-repeat center;
        background-size: cover;
        width: 100%;
        height: 120px;
    }
}

.swal2-dark {
    background-color: #525f7f;
    color: #fff;
}

.swal2-dark .swal2-popup {
    background-color: #3e3e3e;
    color: #fff;
    border: 1px solid #444;
}

.swal2-dark .swal2-title {
    color: #fff;
}

.swal2-dark .swal2-content {
    color: #fff;
}

.swal2-dark .swal2-input {
    background-color: #555;
    color: #fff;
    border: 1px solid #666;
}

.swal2-dark .swal2-confirm {
    background-color: #4caf50;
    color: #fff;
}

.swal2-dark .swal2-cancel {
    background-color: #f44336;
    color: #fff;
}

.swal2-dark .swal2-styled:focus {
    box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.5);
}

.nav-balance {
    justify-content: flex-end;
    width: 100%;

    @media (max-width: 500px) {
        margin-top: -34px;
    }
}

.animated-message {
    display: inline-block;
    padding: 10px 20px;
    background: #ba54f5;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    animation: fadeIn 1s ease-out forwards, fadeOut 2s ease-in 7s forwards;
    margin-bottom: 10px;
    opacity: 0; /* Inicia invisível */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .safe {
    background: radial-gradient(circle,rgba(255,255,230,1) 0%,rgba(116,255,0,1) 100%) !important;
    color: #155724;
  }
  